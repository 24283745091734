import * as actionTypes from './actionTypes'

export const addToCart = product => ({
  type: actionTypes.ADD_PRODUCT_TO_CART,
  product,
})

export const removeFromCart = sku => ({
  type: actionTypes.REMOVE_PRODUCT_FROM_CART,
  sku,
})

export const clearCart = () => ({
  type: actionTypes.CLEAR_CART,
})

import React from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import axios from 'axios'

import Layout from 'components/layout'
import SEO from 'components/seo'
import { Link as GatsbyLink } from 'gatsby'
import { Flex, Box, Text, Button, Heading } from 'rebass/styled-components'
import { useShopContext } from 'context/provider'
import { removeFromCart, clearCart } from 'context/actions'

const CartPage = () => {
  const { state, dispatch } = useShopContext()
  const stripe = useStripe()

  const handleCheckOut = async event => {
    event.preventDefault()

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    try {
      const { err, data } = await axios({
        method: 'POST',
        url: '/.netlify/functions/stripe-create-session',
        data: {
          cart: state.cart,
        },
      })

      if (err) {
        console.log('[error]', err)
      }

      stripe.redirectToCheckout({
        sessionId: data.id,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Layout>
      <SEO title="Cart" />
      <Heading textAlign="center" fontSize={[4, 4, 5]} py={3}>
        Cart
      </Heading>
      <Flex flexDirection="column">
        {state.cart.length === 0 ? (
          <Text fontSize={3}>
            Your cart is empty, continue shopping{' '}
            <GatsbyLink to="/products">here</GatsbyLink>
          </Text>
        ) : (
          state.cart.map((cartItem, idx) => {
            return (
              <Box key={idx} mb={2}>
                <Button
                  as="a"
                  href="#"
                  onClick={() => dispatch(removeFromCart(cartItem.sku))}>
                  Remove
                </Button>
                {` ${cartItem.name} - ${cartItem.quantity}`}
              </Box>
            )
          })
        )}
        {state.cart.length === 0 ? null : (
          <Box alignSelf="flex-end" mb={2}>
            <Button
              as="a"
              href="#"
              width="150px"
              onClick={() => dispatch(clearCart())}>
              Clear Cart
            </Button>
          </Box>
        )}
        {state.cart.length === 0 ? null : (
          <Box alignSelf="flex-end" mb={2}>
            <Button as="a" href="#" width="150px" onClick={handleCheckOut}>
              Checkout
            </Button>
          </Box>
        )}
      </Flex>
    </Layout>
  )
}

export default CartPage
